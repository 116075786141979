<template>
    <b-overlay :show="isLoading">
        <b-card class="white-bg">
            <b-row>
                <b-col>
                    <b-table-simple responsive bordered striped>
                        <b-thead>
                            <b-tr>
                                <b-th colspan="2">Datos Generales</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-th style="width: 12%">Marca: </b-th>
                                <b-td>{{ vehicle.brand }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Modelo: </b-th>
                                <b-td>{{ vehicle.model }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Año: </b-th>
                                <b-td>{{ vehicle.year }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Placa: </b-th>
                                <b-td>{{ vehicle.licensePlate }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Pasajeros: </b-th>
                                <b-td>{{ vehicle.passengerCapacity }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <template #footer>
                <div class="text-right">
                    <b-button variant="outline-secondary" :to="{ name: 'VehicleIndex' }" class="mr-2"><font-awesome-icon icon="angle-left"></font-awesome-icon> Regresar</b-button>
                    <b-button variant="secondary" :to="{ name: 'VehicleEdit', params: { id: vehicle.id }}" v-if="vehicle.id">
                        <font-awesome-icon icon="edit"></font-awesome-icon> Modificar
                    </b-button>
                </div>
            </template>
        </b-card>
    </b-overlay>
</template>

<script>
import { vehicleUrl } from '@routes';
import * as constants from '@constants';

export default {
    data() {
        return {
            vehicle: {
                id: 0,
                brand: '',
                model: '',
                year: '',
                licensePlate: '',
                passengerCapacity: ''
            },
            breadcrumb: {
                title: 'Vehículos',
                path: [
                    {
                        name: 'VehicleIndex',
                        text: 'Vehículos'
                    }
                ]
            },
            isLoading: false
        };
    },
    created() {
        this.vehicle.id = this.$route.params.id ?? 0;

        this.breadcrumb.path.push({
            name: 'VehicleShow',
            text: `Detalle`
        });

        this.$emit('update-breadcrumb', this.breadcrumb);

        this.loadData();
    },
    methods: {
        loadData() {
            this.isLoading = true;

            this.axios.get(vehicleUrl(this.$route.params.id)).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    let vehicle = response.data.vehicle;

                    this.vehicle.id = vehicle.id;
                    this.vehicle.brand = vehicle.brand;
                    this.vehicle.model = vehicle.model;
                    this.vehicle.year = vehicle.year;
                    this.vehicle.licensePlate = vehicle.licensePlate;
                    this.vehicle.passengerCapacity = vehicle.passengerCapacity;
                }
            }).finally(() => {
                this.isLoading = false;
            });
        }
    },
    computed: {
    }
};
</script>

<style scoped>

</style>